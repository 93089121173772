<template>
  <div>
    <v-dialog v-model="olahDataDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          {{ olahDataTitle }}
        </v-card-title>

        <v-divider class="mx-5"></v-divider>

        <v-card-text class="pb-0 mt-3">
          <v-form ref="form" v-model="valid">
            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Kode Gudang
            </v-card-title>
            <v-text-field
              v-model="olahData.kode"
              outlined
              dense
              :rules="kodeGudangRules"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Nama Gudang
            </v-card-title>
            <v-text-field
              v-model="olahData.name"
              outlined
              dense
              :rules="namaGudangRules"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Inisial Gudang
            </v-card-title>
            <v-text-field
              v-model="olahData.inisial"
              outlined
              dense
              :rules="inisialGudangRules"
              autocomplete="off"
            ></v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Tipe Gudang
            </v-card-title>
            <v-select
              v-model="olahData.type_gudang_id"
              :items="tipeGudang"
              item-text="name"
              item-value="id"
              outlined
              dense
              :rules="inisialGudangRules"
              autocomplete="off"
            ></v-select>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Alamat
            </v-card-title>
            <v-textarea
              v-model="olahData.alamat"
              outlined
              dense
              rows="3"
              row-height="15"
              :rules="alamatGudangRules"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0 pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeOlahData"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="checkType"
            :disabled="!valid"
            :loading="loading"
          >
            {{ olahDataBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    olahDataDialog: Boolean,
    typeAction: String,
    olahDataTitle: String,
    olahDataBtn: String,
    olahData: Object,
    tipeGudang: Array,
  },

  data: () => ({
    valid: false,
    loading: false,
    kodeGudangRules: [(v) => !!v || "Kode Gudang tidak boleh kosong"],
    namaGudangRules: [(v) => !!v || "Nama Gudang tidak boleh kosong"],
    inisialGudangRules: [(v) => !!v || "Inisial Gudang tidak boleh kosong"],
    alamatGudangRules: [(v) => !!v || "Alamat Gudang tidak boleh kosong"],
  }),

  methods: {
    checkType() {
      this.olahData.kode = this.olahData.kode.toUpperCase();
      this.olahData.name = this.olahData.name.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      this.olahData.inisial = this.olahData.inisial.toUpperCase();
      this.olahData.alamat = this.olahData.alamat.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      this.loading = true;
      if (this.typeAction === "edit") {
        this.edit();
      } else if (this.typeAction === "new") {
        this.new();
      }
    },
    new() {
      const data = JSON.stringify(this.olahData);
      axios
        .post("v1/admin/gudang", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit("reloadDataGudang");
          this.closeOlahData();
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            console(error.response);
          }
        });
    },
    edit() {
      const data = JSON.stringify(this.olahData);
      axios
        .put("v1/admin/gudang", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.$emit("reloadDataGudang");
            this.closeOlahData();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            console(error.response);
          }
        });
    },
    closeOlahData() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("closeOlahDataDialog");
    },
  },
};
</script>