<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataGudang"
            :items-per-page="itemsPerPage"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="modify" md="4" align="right">
                    <v-btn
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="checkType(0)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah Gudang
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-if="modify" v-slot:item.is_activated="{ item }">
              <v-chip
                small
                class="font-weight-bold error--text"
                v-if="item.is_activated === 0"
              >
                Disabled
              </v-chip>
              <v-chip small class="font-weight-bold primary--text" v-else>
                Active
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="checkType(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                color="#EB4646"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="hapus(item)"
              >
                <span v-if="item.is_activated === 0">Active</span>
                <span v-else>Disabled</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <olah-data
      :olahDataDialog="olahDataDialog"
      :olahDataTitle="olahDataTitle"
      :olahDataBtn="olahDataBtn"
      :olahData="olahData"
      :tipeGudang="tipeGudang"
      :typeAction="typeAction"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadDataGudang="reloadDataGudang"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="430">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold black--text">
          Ubah status Gudang
        </v-card-title>
        <v-card-subtitle class="mt-1 text-subtitle-2 black--text">
          Status Gudang akan dirubah. Yakin melanjutkan proses ini?
        </v-card-subtitle>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeDelete"
          >
            Batal
          </v-btn>
          <v-btn
            color="#EB4646"
            class="white--text"
            style="text-transform: unset !important"
            @click="deleteItemConfirm"
            :loading="loading"
          >
            Proses
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import olahData from "../gudang/olahData.vue";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  components: { olahData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Kode Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "kode",
      },
      {
        text: "Nama Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "alamat",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "alamat",
      },
      {
        text: "Inisial",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "inisial",
      },
      {
        text: "Tipe Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name_type_gudang",
      },
      {
        text: "Status",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "is_activated",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataGudang: [],
    tipeGudang: [],
    itemsPerPage: 500,
    olahDataDialog: false,
    typeAction: "",
    olahDataTitle: "",
    olahDataBtn: "",
    olahData: {},
    progress: false,
    popDialog: false,
    popTitle: "",
    deleteData: {},
    status: "",
    dialogDelete: false,
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios
        .get(
          "v1/user/gudang/list?limit=" +
            this.itemsPerPage
        )
        .then((response) => {
          this.loading = false;
          this.dataGudang = response.data.data;
          this.getTipeGudang();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem('refresh'));
              this.getData();
            }
            console.clear();
          }
        });
    },
    getTipeGudang() {
      axios.get("v1/user/gudang_type/list?limit=200").then((response) => {
        this.tipeGudang = response.data.data;
      })
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    getDataById(id) {
      axios.get("v1/user/gudang/?id=" + id).then((response) => {
        this.olahData = response.data.data;
        if (this.olahData.name != null) {
          this.progress = false;
          this.typeAction = "edit";
          this.olahDataTitle = "Edit Gudang";
          this.olahDataBtn = "Edit & Simpan";
          this.olahDataDialog = true;
        }
      });
    },
    checkType(value) {
      if (value === 0) {
        this.typeAction = "new";
        this.olahDataTitle = "Tambah Gudang";
        this.olahDataBtn = "Tambahkan";
        this.olahDataDialog = true;
      } else {
        this.progress = true;
        this.getDataById(value);
      }
    },
    reloadDataGudang(){
      if(this.typeAction === "edit"){
        this.popTitle = "Data Gudang berhasil diperbaharui";
        this.popDialog = true;
      }else if(this.typeAction === "new"){
        this.popTitle = "Data Gudang berhasil ditambahkan";
        this.popDialog = true;
      }
    },
    closeOlahDataDialog() {
      this.olahData = {};
      this.dataGudang = [];
      this.getData(); // for dummy, don't need this
      this.olahDataDialog = false;
    },
    hapus(value) {
      this.deleteData = value;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.loading = true;
      this.dataGudang = [];
      this.typeAction = "edit";
      const data = this.deleteData;
      if (this.deleteData.is_activated === 0) {
        data.is_activated = 1;
      } else {
        data.is_activated = 0;
      }
      const dataSend = JSON.stringify(data);
      axios
        .put("v1/admin/gudang", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.deleteData = {};
            this.reloadDataGudang();
            this.closeDelete();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            console(error.response);
          }
        });
    },
    closeDelete() {
      this.dataGudang = [];
      this.deleteData = {};
      this.getData();
      this.dialogDelete = false;
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
    refresh() {
      this.refreshAction(localStorage.getItem("refresh")).then(() => {
        this.getData();
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>